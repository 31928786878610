.checkbox-new .ant-checkbox {
    border: 1px solid black !important;
}

.checkbox-new .ant-checkbox .ant-checkbox-inner {
    border: 1px solid black !important;
    background: white !important;
    height: 20px;
    width: 20px;
}

.checkbox-new .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 2px solid black;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1.3) translate(-50%, -50%);
}
.export-border-none .ant-select-selector{
    border:none !important
}
