.header-dropdown{
    height: 50px;
    border-radius: 20px;
}
.header-dropdown button{
    /* background: none; */
    border: none;
    background: white;
    height: 40px;
    border-radius: 20px;
}
.bell-icon{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none
}
.header-button{
    height: 50px;
    background: none;
    border:none
}
.sub-heading{
    font-size: 16px !important;
}
.Admin-bell{
    height: 3rem;
    border-radius: 1px;
    width: 9rem;
}

.image-col{
    padding-right: 1rem;
    padding-top: 4px;
}
.admin-text{
    padding-right: 5px;
    padding-top:10px;
}
.admin-icon{
    padding-right: 9px;
    padding-top:16px;
}
.admin-button{
    display: flex;
    height: 50px;
    width: 9rem;
    background-color: white;
    border: none; 
    border-radius: 10px;
}

.ant-page-header-heading-extra{
    display: flex;
}

