.fancy-serach {
    max-width: 400px;
    margin-bottom: 10px;
}

.fancy-search-input input {
    height: 50px;
    border-radius: 20px;
    border: none
}

.fancy-search-input input:focus {
    border: none
}

.fancy-search-input .ant-input-group-addon {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    font-size: 20px;
    background: white;
    border: none;
    color: black
}

.dropdown-extra-padding {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 30px;
    padding-left: 30px;
}

.ant-dropdown-open{
    background: #27B824 !important;
    color: white;
}
.ant-dropdown-open:hover{
    background: #27B824 !important;
    color: white !important;
}