@import 'antd/dist/antd.css';

.ant-typography {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500 !important;
}

.text-white {
  color: white !important;
}

.g-color {
  color: #27B824
}

.g-backgroung {
  background: #27B824
}

.gray-color {
  color: #D4D4D4 !important;
}

.text-center {
  text-align: center !important;
}

html {
  --antd-wave-shadow-color: #27B824;
  --scroll-bar: 0;
}

.w-100 {
  width: 100% !important;
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-auto {
  margin: auto !important;
}

.ml-1 {
  margin-left: 10px;
}

.ml-2 {
  margin-left: 15px;
}

.ml-3 {
  margin-left: 20px;
}

.ml-4 {
  margin-left: 25px;
}

.ml-5 {
  margin-left: 30px;
}


.mr-1 {
  margin-right: 10px;
}

.mr-2 {
  margin-right: 15px;
}

.mr-3 {
  margin-right: 20px;
}

.mr-4 {
  margin-right: 25px;
}

.mr-5 {
  margin-right: 30px;
}

.mt-1 {
  margin-top: 10px !important;
}

.mt-2 {
  margin-top: 15px !important;
}

.mt-3 {
  margin-top: 20px !important;
}

.mt-4 {
  margin-top: 25px !important;
}

.mt-5 {
  margin-top: 30px !important;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-2 {
  margin-bottom: 15px;
}

.mb-3 {
  margin-bottom: 20px;
}

.mb-4 {
  margin-bottom: 25px;
}

.mb-5 {
  margin-bottom: 30px;
}

.font-weight-bold {
  font-weight: 500 !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-26 {
  font-size: 26px !important;
}

.font-28 {
  font-size: 28px !important;
}

.font-30 {
  font-size: 30px !important;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.border-radius-20 {
  border-radius: 20px !important
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.d-flex {
  display: flex;
}

.j-c-c {
  justify-content: center;
}

.j-c-e {
  justify-content: space-evenly;
}

.orange-color {
  color: #FD6700 !important;
}

.justify-content-right {
  justify-content: end;
}

.fancy-dropdown {
  background: #FAFAFA;
  color: black;
  padding: 10px 15px 10px 15px;
  border-radius: 20px;
  min-width: 120px
}

.fancy-dropdown:focus {
  background: #27B824;
  color: white
}

.a:hover {
  color: none !important
}

.ant-layout {
  background: #FAFAFA !important;
}

.custom-button {
  background: #27B824 !important;
  border: 1px solid #27B824 !important;
  color: white !important;
  height: 50px;
  font-size: 20px;
  border-radius: 20px;
}

.custom-button:hover {
  color: #27B824 !important;
  background: white !important;
  border: 1px solid #27B824 !important;
}


.custom-button-1 {
  background: #27B824 !important;
  border: 1px solid #27B824 !important;
  color: white !important;
  height: 40px;
  font-size: 13px;
  border-radius: 14px;
}

.custom-button-1:hover {
  color: #27B824 !important;
  background: white !important;
  border: 1px solid #27B824 !important;
}



.ant-checkbox .ant-checkbox-inner {
  background-color: white;
  border-color: #27B824;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #27B824;
  border-color: #27B824;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #27B824;
  border-color: #27B824;
}

a:hover {
  color: #27B824 !important;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #27B824 !important;
}

.loader {
  position: fixed;
  z-index: 1;
  background: #FAFAFA !important;
  height: 100%;
  width: 100%;
  opacity: .6;
}

.loader .ant-spin-dot {
  top: 50%;
  opacity: 1;
}

.fadeUp {
  animation: fadeUp 1s alternate;
  /* animation-delay: 1s; */
}

@keyframes fadeUp {
  from {
    transform: translateY(30px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;

  }
}

.fadeIn {
  animation: fadeIn 1s alternate;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;

  }
}

.bor {
  border: 1px solid black;
}

.confirmation-button {
  border-radius: 10px;
}

.KpiScoreCard-container {
  padding: 15px 0px !important;
}

.KpiScoreCard-score {
  font-size: 45px;
  letter-spacing: 4px;
}

.KpiScoreCard-heading {
  color: lightgrey;
  font-size: 12px;
}

.KpiScoreCard-text {
  font-size: 12px;
}

.image-stack {
  margin-left: 10px !important;
}

.dashboard_chart_tooltip {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}