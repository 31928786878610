.custom-dropdown {
  border-radius: 20px 0px 20px 20px;
  width: 200px;
  margin-top: 5px;
  padding: 20px;
  font-weight: 500;
  max-height: 500px;
  overflow-y: scroll;
}

.custom-dropdown li {
  padding-top: 10px;
  padding-bottom: 10px;
}
