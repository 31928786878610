.ant-table-thead>tr>th {
    color: #D4D4D4;
    border-bottom: 1px solid #D4D4D4;
    font-size: 18px;
}

.ant-table-content {
    background: rgb(250, 250, 250);
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none !important;
}

.ant-table-row {
    background: white;
    border-radius: 20px !important;
    margin-top: 10px;
    padding-bottom: 10px;
}

.ant-table table {
    border-spacing: 0px 20px;
}

.ant-table-tbody>tr>td {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 15px;
    font-weight: 500;
}

.rowClassName1 td:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.rowClassName1 td:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;

}

td a {
    color: #26B323 !important;
}

tbody>tr:hover>td {
    background: #FB6300 !important;
    color: white;
}

tbody>tr:hover>td a {
    color: white !important;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus-visible .ant-pagination-item-link {
    background: #26B323 !important;
    border: none;
    color: white;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    font-size: 20px;
}

.ant-pagination-item {
    display: none;
}

.pagination-button {
    background: #26B323 !important;
    border: 1px solid #26B323;
    color: white;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    font-size: 20px;
    margin-right: 10px;
    padding: 0;
}

.pagination-button:hover {
    background: #26B323 !important;
    color: white !important;
    
}