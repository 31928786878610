.form-editadmin {
    background: white;
    padding: 2%;

}

.form-button {
    width: 145px;
    height: 50px;
    border-radius: 40px;
}

.form-button:hover {
    width: 145px;
    height: 50px;
    border-radius: 40px;
    background-color: #27B824;
}

.form-button_addNew {
    width: 200px !important;
    height: 50px !important;
    border-radius: 40px;
    background-color: #27B824 !important;
    border: none !important;
    outline: none !important;
}

.form-button_addNew:hover {
    background-color: white !important;
    color: #27B824 !important;
    border: 1px solid #27B824 !important;
    font-size: 18px;
}

/* .Account-icon{
    padding-left: 4rem;
    padding-top: 1rem;
} */
/* .Account-text{
    padding-left: 10px;
    padding-top: 13px;
} */

.heading {
    margin-top: 1rem;
}

.edit-admin-button {
    padding-right: 4rem;
    color: black;
}

.sub-title {
    color: #27B824;
}

.edit-profile-button {
    padding-right: 30px;
    font-weight: 400 !important;
}