.form-input-custom-2 {
    border: none !important;
    border-bottom: 1px solid lightgray !important;
    width: 100%;

}

.modal-form {
    width: 1010px !important;
    height: 677px !important;

}

.mt-3 {
    margin-top: 5px !important;
}

.form-input-custom-3 {
    border: none !important;
    border-bottom: 1px solid lightgray !important;
    width: 12rem;
    margin: 2px;
}

.form-input-custom-4 {
    border: none !important;
    border-bottom: 1px solid lightgray !important;
    width: 12rem;
    margin: 2px;
}

.form-button {
    float: right;
    margin: 5px;
    width: 6rem;
    height: 3rem;
    background: #F8F8F8;
    border: none;
    border-radius: 15px;
    color: black;
}

.form-button:hover {
    background: #25B222;
}