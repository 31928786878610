.box-shadow {
    box-shadow: rgb(0 0 0 / 25%) 0px 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px, rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px, rgb(0 0 0 / 9%) 0px -3px 5px;
    cursor: pointer;
}

.custom-input-fancy {
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 1px solid #707070;
}

.custom-input-fancy:focus{
    border: 1px solid #27B824 !important
}





