@media only screen and (max-width: 600px) {
    .cover-image{
        display: none;
    }
}
.cover-image{
    background-image: url('../../assets/images/cover1.png');
    background-size: contain;
    width: 100%;
    height: 100vh;
}
.form-input-custom{
    height: 50px;
    margin-top: 10px;
    border-radius: 20px;
}
.responsive-card-login{
    width: 400px;
}
@media only screen and (max-width: 600px) {
    .responsive-card-login{
        width: 300px;
    }
}



