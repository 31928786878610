#components-layout-demo-responsive .logo {
  height: 32px;
  margin: 16px;
  /* background: rgba(255, 255, 255, 0.2); */
}

.ant-layout-sider-children {
  height: 100vh !important;
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}
.ant-layout-sider{
  background: none;
}
.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after{
  border-right: none !important;
}
.ant-menu{
  font-size: 16px !important;
}
.ant-menu-item{
  margin-bottom:20px !important;
  margin-top: 20px !important;
  
}
.ant-menu > .ant-menu-item:hover,
.ant-menu > .ant-menu-submenu:hover,
.ant-menu > .ant-menu-item-active,
.ant-menu> .ant-menu-submenu-active,
.ant-menu > .ant-menu-item-open,
.ant-menu> .ant-menu-submenu-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected {
  color: #26B523 !important;
  background: #EFFFEF !important;
  border-radius: 20px;
}
.ant-menu{
  color: #D4D4D4;
}





