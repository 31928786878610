.green-color {
    color: #25B222;
}

.dropdown-link {
    width: 2rem;
    height: 2rem;
}

.detail-button {
    border: none;
    color: black;
    background: #FAFAFA !important;
    height: 50px;
    width: 60px;
    border-radius: 20px;
}

.detail-button:hover {
    color: #25B222;
}
.detail-button:focus {
    color: #25B222;
    background: #FAFAFA !important;
}

.notes {
    background: #F8F8F8;
    margin-right: 10px;
    padding: 10px 20px;
    border-radius: 20px;
    display: flex;
    margin-bottom: 10px;
}

.display-responsive {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.cross-button {
    background: none;
    border: none;
    height: 10px;
    padding: 0;
    color: #25B222
}

.cross-button:hover {
    color: #25B222;
}
.no-border .ant-select-selector{
    border:none !important;
    border-bottom: 1px solid lightgray !important;
}
.no-border-none  .ant-select-selector{
    height: 60px !important;
}
.fancy-border {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid lightgray !important;
    width: 90%;
}